.MarkerIcon {
    border: 1px solid rgba(0, 55, 95, 0.3);
    border-radius: 50%;
    background-color: #99F2E8;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.MarkerIcon:hover {
    background-color: #ffffff;
    border-color: rgb(0, 128, 224);
}

.ToolTip .leaflet-popup-content-wrapper,
.ToolTip .leaflet-popup-tip {
    background-color: rgba(3, 55, 157, 0.5);
    border: 1px solid rgb(0, 128, 224);
}

.ToolTip .leaflet-popup-content-wrapper {
    backdrop-filter: blur(10px);
    border-radius: 5px;
}

.ToolTip .leaflet-popup-tip-container {
    margin-top: -1px;
    display: none;
}
