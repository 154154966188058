.MapComponent {
    height: 470px;
    width: 100%;
    background-color: #A0C7EE;
}
@media only screen and (min-width: 667px) {
    .MapComponent {
        height: 100%;
        width: 100%;
    }
}
