.ChartsContainer {
  display: flex;
}

.ChartsContainerInner {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #5275ba;
  padding: 20px 10px 20px 20px;
}

.ChartsArea {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
}

.ChartsArea::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.ChartsArea::-webkit-scrollbar-thumb {
  background: #97bae2;
  border-radius: 5px;
}
.ChartsArea::-webkit-scrollbar-thumb:hover {
  background: #97bae2;
}
.ChartsArea::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}
@media only screen and (min-width: 667px) {
  .ChartsContainer {
    position: absolute;
    width: 46%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1001;
  }
  .ChartsContainerInner {
    background-color: rgba(3, 55, 157, 0.5);
    margin: 20px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
  }
}
@media only screen and (min-width: 1171px) {
  .ChartsContainer {
    width: 37%;
  }
}
.socio-eco-data-container {
  color: white;
}
.socio-eco-data-container > * {
  color: white;
}

.disable-download-button {
  opacity: 0.7;
  pointer-events: none;
}
.download-csv-link {
  color: white;
}
.download-csv-link:hover {
  cursor: pointer;
}
