@media only screen and (min-width: 667px) {
  #RangeContainer {
    width: 50%;
  }
}

@media only screen and (min-width: 1171px) {
  #RangeContainer {
    width: 60% !important;
  }
}
