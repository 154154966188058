.DashboardContainer{
    position: relative;
    margin: 0 auto;
}
@media only screen and (min-width: 667px) {
    .DashboardContainer {
        position: fixed;
        height: 100%;
        width: 100%;
    }
}
